<template>
    <div class="wrapper pt-3 pb-5">
        <div class="card text-white">
            <h1 class="text-primary mb-1">404</h1>
            <strong class="mb-3"
                >The page you requested could not be found.</strong
            >
            <ul class="text-left mb-3">
                <li>
                    If you typed in the address, please make sure everything is
                    spelled correctly.
                </li>
                <li>
                    If you followed a link, it could be broken. Please
                    <b-link rel="external" :href="mailtoUrl">contact us</b-link>
                    and we'll fix it.
                </li>
            </ul>
            <p class="suggested-links d-flex justify-content-around mb-2">
                <b-link to="/search">Search</b-link>
                <b-link to="/ontologies">Ontologies</b-link>
                <b-link rel="external" target="_blank" :href="helpUrl"
                    >Support</b-link
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorMain',
    props: {
        appName: {
            type: String,
            default: process.env.VUE_APP_NAME,
        },
        appVer: {
            type: String,
            default: process.env.VUE_APP_VER,
        },
        helpUrl: {
            type: String,
            default: process.env.VUE_APP_HELP_URL,
        },
        helpEmail: {
            type: String,
            default: process.env.VUE_APP_HELP_EMAIL,
        },
    },
    computed: {
        mailtoUrl: function () {
            return (
                'mailto:' +
                this.helpEmail +
                '?subject=' +
                this.appName +
                ' v' +
                this.appVer +
                '%20-%20Broken%20link&body=%0A%0A%0A%0A______________%0A' +
                this.$route.fullPath
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.wrapper {
    @include background('../assets/error.jpg');

    .card {
        @include card-centered;
        padding: 1.25rem;
        text-align: center;
        background: rgba($dark-green, 0.75);

        h1 {
            line-height: 1em;
            font-size: 10rem;
            font-weight: bold;
        }

        strong {
            font-size: 1.5rem;
        }

        .suggested-links {
            font-weight: 500;
        }
    }

    @media (min-height: 600px) {
        @include screen-centered;
    }
}
</style>
